import * as Sentry from "@sentry/vue";
import { createAuth } from "@websanova/vue-auth";
import driverHttpAxios from "@websanova/vue-auth/dist/drivers/http/axios.1.x.esm.js";
import driverRouterVueRouter from "@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm.js";
import type {
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
} from "axios";
import { decodeJwt } from "jose";
import type { RouteLocation } from "vue-router";

import {
  ACCESS_TOKEN_KEY,
  ACCESS_TOKEN_STORAGE_KEY,
  REFRESH_TOKEN_KEY,
  REFRESH_TOKEN_STORAGE_KEY,
} from "@/common/utils/constants";
import { getUngzippedString } from "@/common/utils/stringUtils";
import cognito from "@/misc/drivers/cognito";
import type { AdminApp } from "@/misc/types";
import { isRefreshRequest } from "@/plugins/utils/isRefreshRequest";

const cognitoApiUrl = import.meta.env.VITE_COGNITO_API;

type Transition = {
  href: string;
  /** we narrow the type of 'meta' here to what we know we use on routes */
  meta: { auth?: string[] | Record<"roles", string[]> };
} & RouteLocation;

type UserResponse = {
  email: string;
  email_verified: boolean;
  sub: string;
  username: string;
};

/**
 * customDriver provides interceptors for our auth requests and responses
 */
const customDriver = () => ({
  request: function (request: AxiosRequestConfig, token: string) {
    request.headers = request.headers || ({} as AxiosRequestHeaders);

    if (token) {
      request.headers["Authorization"] = `Bearer ${token}`;
    }

    if (
      isRefreshRequest(request) ||
      request.url?.includes(`${cognitoApiUrl}/oauth2/revoke`)
    ) {
      request.headers["Authorization"] = undefined;
      request.headers["Content-Type"] = "application/x-www-form-urlencoded";
    }

    if (request.url?.split("?")?.[0]?.includes("amazonaws.com")) {
      request.headers["Authorization"] = undefined;
    }
  },
  response: function (response: AxiosResponse) {
    // we store the refreshToken in localStorage for the next refresh call
    const refreshToken = response.data?.[REFRESH_TOKEN_KEY];
    if (refreshToken) {
      localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, refreshToken);
    }

    // update access token
    const token = response.data?.[ACCESS_TOKEN_KEY];
    if (token) {
      localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, token);
    }

    return token;
  },
});

/**
 * This plugin is responsible for handling authentication and authorization.
 * See this link for more details on the package: https://websanova.com/docs/vue-auth
 * See this link for more info on the endpoints: https://docs.aws.amazon.com/cognito/latest/developerguide/federation-endpoints.html
 */
export default (app: AdminApp) => {
  const auth = createAuth({
    plugins: {
      http: app.axios,
      router: app.router,
    },
    drivers: {
      http: driverHttpAxios,
      auth: customDriver(),
      oauth2: {
        cognito,
      },
      router: driverRouterVueRouter,
    },
    options: {
      tokenDefaultKey: ACCESS_TOKEN_STORAGE_KEY,
      stores: ["storage"],
      oauth2Data: {
        url: `${cognitoApiUrl}/oauth2/token`,
        method: "POST",
        fetchUser: true,
      },
      loginData: {
        enabled: false,
      },
      refreshData: {
        url: `${cognitoApiUrl}/oauth2/token`,
        method: "POST",
        enabled: true,
        data: {
          grant_type: "refresh_token",
          client_id: cognito.params.client_id,
          refresh_token: localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY),
        },
      },
      fetchData: {
        url: `${cognitoApiUrl}/oauth2/userInfo`,
      },
      logoutData: {
        method: "POST",
        url: `${cognitoApiUrl}/oauth2/revoke`,
      },
      rolesKey: "permissions",
      parseUserData(user: UserResponse) {
        const jwt = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
        if (!jwt) return user;

        const decodedJwt = decodeJwt(jwt);

        let stringifiedData: string | undefined;
        try {
          stringifiedData = getUngzippedString(decodedJwt.perm as string);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.warn("Could not parse permissions from JWT", error);
        }

        Sentry.setUser({ email: user.email });

        return {
          ...user,
          permissions: stringifiedData && JSON.parse(stringifiedData),
        };
      },
      forbiddenRedirect: (transition: Transition) => {
        let requiredPermissions;
        if (transition.meta.auth && Array.isArray(transition.meta.auth)) {
          requiredPermissions = transition.meta.auth.join(",");
        }

        if ((transition.meta.auth as Record<"roles", string[]>)?.roles) {
          requiredPermissions = (
            transition.meta.auth as Record<"roles", string[]>
          ).roles.join(",");
        }

        return `/403?requiredPermissions=${requiredPermissions}&pathName=${String(
          transition.name,
        )}`;
      },
    },
  });

  app.use(auth);
  app.provide("$auth", auth); // making it available from within Vue components via `inject`
  app.auth = auth; // making it available for other plugins
};
