import _definePage_default_0 from '/app/src/pages/login.vue?definePage&vue&lang.tsx'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'

export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/app/src/pages/index.vue'),
    /* no children */
    meta: {
      "auth": true
    }
  },
  {
    path: '/:slug(.*)',
    name: '/[...slug]',
    component: () => import('/app/src/pages/[...slug].vue'),
    /* no children */
    meta: {
      "auth": true
    }
  },
  {
    path: '/403',
    name: '/403',
    component: () => import('/app/src/pages/403.vue'),
    /* no children */
    meta: {
      "auth": true
    }
  },
  {
    path: '/beneficiaries',
    /* internal name: '/beneficiaries' */
    /* no component */
    children: [
      {
        path: '',
        name: '/beneficiaries/',
        component: () => import('/app/src/beneficiaries/pages/index.vue'),
        /* no children */
        meta: {
          "auth": true
        }
      },
      {
        path: ':beneficiaryId',
        name: '/beneficiaries/[beneficiaryId]',
        component: () => import('/app/src/beneficiaries/pages/[beneficiaryId].vue'),
        /* no children */
        meta: {
          "auth": true
        }
      }
    ],
    meta: {
      "auth": true
    }
  },
  _mergeRouteRecord(
  {
    path: '/login',
    name: '/login',
    component: () => import('/app/src/pages/login.vue'),
    /* no children */
  },
  _definePage_default_0
  ),
  {
    path: '/vendors',
    /* internal name: '/vendors' */
    /* no component */
    children: [
      {
        path: '',
        name: '/vendors/',
        component: () => import('/app/src/vendors/pages/index.vue'),
        /* no children */
        meta: {
          "auth": true
        }
      },
      {
        path: ':vendorId',
        name: '/vendors/[vendorId]',
        component: () => import('/app/src/vendors/pages/[vendorId]/layout.vue'),
        children: [
          {
            path: 'contracts',
            name: '/vendors/[vendorId]/contracts',
            component: () => import('/app/src/vendors/pages/[vendorId]/contracts.vue'),
            /* no children */
            meta: {
              "auth": true
            }
          },
          {
            path: 'customer-invoices',
            name: '/vendors/[vendorId]/customer-invoices',
            component: () => import('/app/src/vendors/pages/[vendorId]/customer-invoices.vue'),
            /* no children */
            meta: {
              "auth": true
            }
          },
          {
            path: 'details',
            name: '/vendors/[vendorId]/details',
            component: () => import('/app/src/vendors/pages/[vendorId]/details.vue'),
            /* no children */
            meta: {
              "auth": true
            }
          },
          {
            path: 'vendor-invoices',
            name: '/vendors/[vendorId]/vendor-invoices',
            component: () => import('/app/src/vendors/pages/[vendorId]/vendor-invoices.vue'),
            /* no children */
            meta: {
              "auth": true
            }
          }
        ],
        meta: {
          "auth": true
        }
      }
    ],
    meta: {
      "auth": true
    }
  }
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

