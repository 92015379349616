<script setup lang="ts">
import { useRouteQuery } from "@vueuse/router";
import { useAuth } from "@websanova/vue-auth";
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import useFormKitErrors from "@/common/composables/useFormKitErrors";
import { BLACK_MWS_LOGO_URL } from "@/common/utils/constants";

import PlainLayout from "../layouts/PlainLayout.vue";
import GoogleLogo from "../misc/assets/google_logo.png";
import cognito from "../misc/drivers/cognito";

definePage({
  meta: { layout: PlainLayout },
  beforeEnter: () => {
    const auth = useAuth();

    if (auth?.check()) return { name: "/" };
  },
});

const auth = useAuth();
const returnUrl = useRouteQuery("returnUrl", "", { transform: String });
const getRedirectRoute = () => {
  if (auth.redirect()?.from) {
    return auth.redirect()?.from;
  }

  if (returnUrl.value) {
    return returnUrl.value;
  }

  return { name: "/" };
};

const isPending = ref(false);
const { onError } = useFormKitErrors("login-form");
const onSubmit = async () => {
  try {
    isPending.value = true;
    // getting the code from cognito so we can exchange it for an access token
    await auth.oauth2("cognito", {
      params: {
        state: { returnUrl: getRedirectRoute() },
      },
    });
  } catch (e) {
    onError(e);
    isPending.value = false;
  }
};

const exchangeCodeForToken = async (code: string) => {
  const params = new URLSearchParams();

  params.append("code", code);
  params.append("grant_type", "authorization_code");
  params.append("redirect_uri", `${window.location.origin}/login`);
  params.append("client_id", cognito.params.client_id);

  await auth.oauth2("cognito", { code: true, data: params.toString() });
};

const router = useRouter();
const route = useRoute();
onMounted(async () => {
  const code = route.query["code"];
  const state = route.query["state"];

  if (!code) return;

  if (typeof code !== "string") throw new Error("Invalid code");

  try {
    isPending.value = true;
    await exchangeCodeForToken(code);

    const stateObject = typeof state === "string" && JSON.parse(state);
    router.replace(stateObject.returnUrl ?? "/");
  } catch (e) {
    onError(e);
    isPending.value = false;
  }
});
</script>

<template>
  <div class="flex flex-col items-center bg-gray-100 space-y-6 mt-24">
    <div class="bg-white p-12 rounded-md flex flex-col">
      <img
        class="h-12 w-auto mx-auto"
        :src="BLACK_MWS_LOGO_URL"
        alt="MatchWornShirt"
      />
      <FormKitForm
        id="login-form"
        :actions="false"
        messages-class="order-2"
        form-class="mt-8"
        :disabled="isPending"
        @submit="onSubmit"
      >
        <FormKit
          type="submit"
          data-cy="login-submit-button"
          input-class="min-w-72 w-max"
        >
          <span
            v-if="isPending"
            class="animate-ping grow"
          >
            ...
          </span>
          <span
            v-else
            class="flex justify-center grow"
          >
            <img
              class="size-5 mr-2"
              :src="GoogleLogo"
            />
            Sign in via Google Workspace
          </span>
        </FormKit>
      </FormKitForm>
    </div>
  </div>
</template>
