<script setup lang="ts">
import {
  Combobox,
  ComboboxInput,
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import debounce from "lodash/debounce";
import entries from "lodash/entries";
import { type AdminCustomerDto, AdminCustomersApi } from "mws-finance-ts-sdk";
import { type Ref, ref, toRefs, watch } from "vue";
import { useRouter } from "vue-router";

import CommandPaletteInfo from "@/common/components/menu/commandPalette/CommandPaletteInfo.vue";
import EmptyResultView from "@/common/components/menu/commandPalette/EmptyResultView.vue";
import ResultsList from "@/common/components/menu/commandPalette/ResultsList.vue";
import { getCustomerAdminPageLink } from "@/customers/utils";
import { useEventStore } from "@/events/store";
import type { MarketplaceEvent } from "@/events/types";
import { useRealTimeScanning } from "@/fabricks/chips/composables/useRealTimeScanning";
import { useProductListingStore } from "@/products/store";
import type { AdminProductSku, ProductSku } from "@/products/types";

import FindWithChip from "./FindWithChip.vue";
import BaseIcon from "../../icons/BaseIcon.vue";

const props = defineProps<{
  isOpen: boolean;
}>();

const emit = defineEmits<{
  (e: "update:isOpen", value: boolean): void;
}>();

const router = useRouter();

const eventStore = useEventStore();
const productListingStore = useProductListingStore();

const searchTerm = ref("");
const pageSize = 5;
const isPending = ref(false);

export type ItemGroup<T> = {
  label: string;
  items: T[];
  seeAllLink: string;
};

const groups: Ref<{
  productListings: ItemGroup<AdminProductSku>;
  events: ItemGroup<MarketplaceEvent>;
  customers: ItemGroup<AdminCustomerDto>;
}> = ref({
  events: {
    label: "Events",
    items: [],
    seeAllLink: "/events",
  },
  productListings: {
    label: "Product Listings",
    items: [],
    seeAllLink: "/product-listings",
  },
  customers: {
    label: "Customers",
    items: [],
    seeAllLink: "/customers",
  },
});

// Each time the search term changes, we make a new search. We debounce the search so that it doesn't fire at each key stroke
watch(
  () => searchTerm.value,
  debounce(async () => {
    if (!searchTerm.value) return;

    isPending.value = true;

    await Promise.all([
      searchProductListings(pageSize),
      searchEvents(pageSize),
      searchForCustomers(pageSize),
    ]);

    isPending.value = false;
  }, 250),
);

const pushToPage = (item: ProductSku | MarketplaceEvent | AdminCustomerDto) => {
  emit("update:isOpen", false);
  const isCustomer = (
    item: ProductSku | MarketplaceEvent | AdminCustomerDto,
  ): item is AdminCustomerDto => {
    return (item as AdminCustomerDto).emailAddress !== undefined;
  };

  router.push(
    isCustomer(item) ? getCustomerAdminPageLink(item) : item.getAdminPageLink(),
  );
};

/* helper functions below */

const searchProductListings = async (pageSize: number) => {
  const response = await productListingStore.search(searchTerm.value, pageSize);

  groups.value.productListings.items = response.results;
};

const searchEvents = async (pageSize: number) => {
  const response = await eventStore.search(searchTerm.value, pageSize);

  groups.value.events.items = response.results;
};

const financeApiUrl = import.meta.env.VITE_FINANCE_API;
const adminCustomersApi = new AdminCustomersApi(undefined, financeApiUrl);

const searchForCustomers = async (pageSize: number) => {
  const { data } = await adminCustomersApi.adminCustomersGet({
    searchTerm: searchTerm.value,
    pageSize,
  });

  groups.value.customers.items = data.results;
};

const isResultEmpty = () => {
  for (const [, group] of entries(groups.value)) {
    if (group.items.length > 0) {
      return false;
    }
  }

  return true;
};

const { isOpen } = toRefs(props);
const chipScannedEvent = useRealTimeScanning({
  isActive: isOpen,
});
</script>

<template>
  <TransitionRoot
    :show="isOpen"
    as="template"
    :appear="true"
    @after-leave="searchTerm = ''"
  >
    <Dialog
      as="div"
      class="command-palette"
      @close="$emit('update:isOpen', false)"
    >
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="command-palette__site-background" />
      </TransitionChild>

      <div class="command-palette__content__container">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 scale-100"
          leave-to="opacity-0 scale-95"
        >
          <DialogPanel class="command-palette__content__dialog-panel">
            <Combobox @update:model-value="pushToPage">
              <div class="command-palette__search">
                <BaseIcon
                  name="heroicons:magnifying-glass"
                  class="command-palette__search__icon"
                  aria-hidden="true"
                />
                <ComboboxInput
                  class="command-palette__search__input"
                  placeholder="Search..."
                  @change="searchTerm = $event.target.value"
                />
              </div>

              <p
                v-if="isPending"
                class="command-palette__info-container"
              >
                Searching...
              </p>

              <!-- isn't pending -->
              <div v-else>
                <div v-if="searchTerm !== ''">
                  <EmptyResultView v-if="isResultEmpty()" />

                  <ResultsList
                    v-else
                    :groups="groups"
                    :search-term="searchTerm"
                  />
                </div>

                <CommandPaletteInfo v-else />
              </div>
            </Combobox>

            <FindWithChip
              v-if="chipScannedEvent"
              class="absolute top-0 inset-x-0 size-full"
              :chip-scanned-event="chipScannedEvent"
              @link-clicked="$emit('update:isOpen', false)"
            />
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style scoped lang="postcss">
.command-palette {
  @apply relative z-10;

  &__site-background {
    @apply fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity;
  }

  &__content {
    &__container {
      @apply fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20;
    }
    &__dialog-panel {
      @apply mx-auto max-w-xl transform overflow-hidden rounded-xl bg-white shadow-2xl shadow-gray-800 transition-all;
    }
  }

  &__search {
    @apply relative ring-0;

    &__icon {
      @apply pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400;
    }

    &__input {
      @apply rounded-t-xl h-12 w-full outline-0 ring-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 sm:text-sm;
    }
  }

  &__info-container {
    @apply border-t border-gray-100 py-14 px-6 text-center text-sm sm:px-14;
  }
}
</style>
