import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import axios from "axios";
import {
  type AdminBidDto,
  AdminProductsApi,
  type AdminProductSkuDto,
  type AdminProductsSkuIdPatchRequest,
  type CreateProductListingRequest,
  EventStatus,
  SalesMethod,
  SkuVisibility,
  type UpdateProductDisplayPriceRequest,
  type UpdateProductPriceRequest,
} from "mws-catalog-ts-sdk";
import {
  type AdminListing,
  AdminProductListingsApi,
  type V2AdminProductListingsIdPatchRequest,
} from "mws-ts-v2-sdk-admin";
import type { Ref } from "vue";
import { useToast } from "vue-toastification";

import type {
  DateTime,
  Guid,
  UseQueryOptionsOmitQueryKey,
} from "@/common/types";
import type { ProductSku } from "@/products/types";

const catalogApiUrl = import.meta.env.VITE_CATALOG_API;
const adminProductsApi = new AdminProductsApi(undefined, catalogApiUrl);
const adminProductListingApi = new AdminProductListingsApi(
  undefined,
  catalogApiUrl,
);

export const useGetProductListingsListQuery = (
  searchTerm: Ref,
  pageSize: Ref,
  offset: Ref,
  filters: Ref<{
    platformKey: string;
    sortColumn?: string;
    sortDirection?: string;
    salesMethodFilter?: SalesMethod;
    productId?: string;
    eventId?: string;
    eventStatus?: EventStatus;
    visibility?: SkuVisibility;
    endDate?: DateTime;
    categoryId?: string;
  }>,
) => {
  const queryKey = ["products", { searchTerm, offset, pageSize, filters }];

  const queryFn = async () => {
    return (
      await adminProductsApi.adminProductsGet({
        xPlatformKey: filters?.value.platformKey,
        searchTerm: searchTerm.value,
        offset: offset.value,
        size: pageSize.value,
        sortColumn: filters?.value.sortColumn,
        sortDirection: filters?.value.sortDirection,
        salesMethodFilter: filters?.value.salesMethodFilter,
        productId: filters?.value.productId,
        eventId: filters?.value.eventId,
        eventStatus: filters?.value.eventStatus,
        visibility: filters?.value.visibility,
        endDate: filters?.value.endDate,
        categoryId: filters?.value.categoryId,
      })
    ).data;
  };

  const options = { keepPreviousData: true };

  return useQuery({ queryKey, queryFn, ...options });
};

export const useGetProductListingByIdQuery = (
  productListingId: Ref<Guid | undefined>,
  options?: UseQueryOptionsOmitQueryKey<AdminProductSkuDto>,
) => {
  const queryKey = ["products", productListingId];

  const queryFn = async () => {
    return (
      await adminProductsApi.adminProductsSkuIdGet({
        skuId: productListingId.value || "",
      })
    ).data;
  };

  return useQuery({ queryKey, queryFn, ...options });
};

export const useGetProductListingByIdV2Query = (
  productListingId: Ref<Guid | undefined>,
  options?: UseQueryOptionsOmitQueryKey<AdminListing>,
) => {
  const queryKey = ["products", productListingId, "V2"];

  const queryFn = async () => {
    return (
      await adminProductListingApi.v2AdminProductListingsIdGet({
        id: productListingId.value || "",
      })
    ).data;
  };

  return useQuery({ queryKey, queryFn, ...options });
};

export const usePatchProductListingDetailsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (request: {
      id: string;
      v2AdminProductListingsIdPatchRequest: V2AdminProductListingsIdPatchRequest;
    }) => {
      const response =
        await adminProductListingApi.v2AdminProductListingsIdPatch(request);

      return response.data;
    },

    onSuccess: (_, request) => {
      queryClient.invalidateQueries({ queryKey: ["products", request.id] });
    },
  });
};

export const usePatchProductListingMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (request: {
      skuId: Guid;
      adminProductsSkuIdPatchRequest: AdminProductsSkuIdPatchRequest;
    }) => {
      const response = await adminProductsApi.adminProductsSkuIdPatch(request);

      return response.data;
    },

    onSuccess: (_, request) => {
      queryClient.invalidateQueries({ queryKey: ["products", request.skuId] });
    },
  });
};

export const usePatchProductListingPriceMutation = () => {
  const toast = useToast();

  return useMutation({
    mutationFn: async (request: {
      skuId: Guid;
      updateProductPriceRequest: UpdateProductPriceRequest;
    }) => {
      const response = await adminProductsApi.adminProductsSkuIdPricesPatch({
        skuId: request.skuId,
        adminProductsSkuIdPricesPatchRequest: request.updateProductPriceRequest,
      });

      return response.data;
    },

    onSuccess: () => {
      toast.success("Prices Updated Succesfully!");
    },
  });
};

export const usePatchProductListingDisplayPriceMutation = () => {
  const toast = useToast();

  return useMutation({
    mutationFn: async (request: {
      skuId: Guid;
      updateProductDisplayPriceRequest: UpdateProductDisplayPriceRequest;
    }) => {
      const response =
        await adminProductsApi.adminProductsSkuIdDisplayPricesPatch({
          skuId: request.skuId,
          adminProductsSkuIdDisplayPricesPatchRequest:
            request.updateProductDisplayPriceRequest,
        });

      return response.data;
    },

    onSuccess: () => {
      toast.success("Display Prices Updated Succesfully!");
    },
  });
};

export const useCreateProductListingMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (request: CreateProductListingRequest) => {
      const response = await adminProductsApi.adminProductsPost({
        adminProductsPostRequest: request,
      });

      return response.data;
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["products"] });
    },
  });
};

export const useUpdateProductListingMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (productListing: Partial<ProductSku>) => {
      const response = await axios.patch(
        `${catalogApiUrl}/admin/products/${productListing.id}/`,
        productListing,
      );

      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["userOrders"] });
      queryClient.invalidateQueries({ queryKey: ["orders"] });
    },
  });
};

export const useGetProductListingBidsQuery = (
  productId: Ref<Guid>,
  options?: UseQueryOptionsOmitQueryKey<AdminBidDto[]>,
) => {
  const queryKey = ["products", productId, "bids"];

  const queryFn = async () => {
    return (
      await adminProductsApi.adminProductsSkuIdBidsGet({
        skuId: productId.value,
      })
    ).data;
  };

  return useQuery({ queryKey, queryFn, ...options });
};
