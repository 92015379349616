export const payoutRoutes = [
  {
    path: "/contract-payouts",
    name: "ContractPayoutListPage",
    component: () => import("../payouts/views/ContractPayoutListPage.vue"),
    meta: {
      auth: {
        roles: ["Admin.Access"],
      },
    },
  },
];
