export const partnerRoutes = [
  {
    path: "/partners/create",
    name: "CreatePartnerPage",
    component: () => import("../partners/views/CreatePartnerPage.vue"),
    meta: {
      auth: {
        roles: ["Admin.Access"],
      },
    },
  },
  {
    path: "/partners/:partnerId",
    name: "PartnerDetailPage",
    component: () => import("./views/PartnerDetailPage.vue"),
    meta: {
      auth: {
        roles: ["Admin.Access"],
      },
    },
    children: [
      {
        path: "",
        name: "PartnerDetailPage.Details",
        component: () => import("./views/PartnerDetail.vue"),
        meta: {
          auth: {
            roles: ["Admin.Access"],
          },
        },
      },
      {
        path: "contracts",
        name: "PartnerDetailPage.Contracts",
        component: () => import("./views/PartnerContractList.vue"),
        meta: {
          auth: {
            roles: ["Admin.Access"],
          },
        },
      },
      {
        path: "vendors",
        name: "PartnerDetailPage.Vendors",
        component: () => import("./views/PartnerVendorList.vue"),
        meta: {
          auth: {
            roles: ["Admin.Access"],
          },
        },
      },
      {
        path: "accounts",
        name: "PartnerDetailPage.Account",
        component: () => import("./views/PartnerAccountList.vue"),
        meta: {
          auth: {
            roles: ["Admin.Access"],
          },
        },
      },
      {
        path: "documents",
        name: "PartnerDetailPage.Documents",
        component: () => import("./views/PartnerDocumentsPage.vue"),
        meta: {
          auth: {
            roles: ["PartnerDocuments.Read"],
          },
        },
      },
    ],
  },
  {
    path: "/partners/:partnerId/accounts/:accountId",
    name: "PartnerAccountPage",
    component: () => import("./views/PartnerAccount.vue"),
    meta: {
      auth: {
        roles: ["Admin.Access"],
      },
    },
    children: [
      {
        path: "",
        name: "PartnerAccountPage.Details",
        component: () => import("./components/PartnerAccountDetails.vue"),
        meta: {
          auth: ["Admin.Access"],
        },
      },
      {
        path: "address",
        name: "PartnerAccountPage.Addresses",
        component: () => import("./components/PartnerAccountAddresses.vue"),
        meta: {
          auth: ["Admin.Access"],
        },
      },
    ],
  },
  {
    path: "/partners",
    name: "PartnersListPage",
    component: () => import("./views/PartnersListPage.vue"),
    meta: {
      auth: {
        roles: ["Admin.Access"],
      },
    },
  },
];
