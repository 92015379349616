<script setup lang="ts">
import { computed, toRefs } from "vue";

import BaseIcon, { type BaseIconProps } from "./icons/BaseIcon.vue";
import { getIsExternalLink } from "../utils/getIsExternalLink";

const props = withDefaults(
  defineProps<{
    variant: "primary" | "secondary" | "confirm" | "cancel";
    inlineBlock?: boolean;
    label?: string;
    iconName?: BaseIconProps["name"];
    iconPosition?: "start" | "end";
    path?: string;
  }>(),
  {
    label: undefined,
    iconName: undefined,
    iconPosition: "start",
    path: undefined,
  },
);

const { path } = toRefs(props);

const componentType = computed(() => {
  if (!path.value) return "button";

  return getIsExternalLink(path.value) ? "a" : "RouterLink";
});

const additionalAttributes = computed(() => {
  if (componentType.value === "a") return { href: path.value };
  else if (componentType.value === "RouterLink") return { to: path.value };

  return {};
});
</script>

<template>
  <component
    :is="componentType"
    v-bind="{ ...$attrs, ...additionalAttributes }"
    :class="`${variant}-button`"
  >
    <div class="interaction-white">
      <BaseIcon
        v-if="iconName && iconPosition === 'start'"
        :name="iconName"
        :size="24"
        :class="{ 'mr-1.5': label }"
      />
      <span v-if="label">
        {{ label }}
      </span>
      <BaseIcon
        v-if="iconName && iconPosition === 'end'"
        :name="iconName"
        :size="24"
        :class="{ 'ml-1.5': label }"
      />
    </div>
  </component>
</template>

<style lang="postcss" scoped>
a,
button {
  @apply inline-flex items-center justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:w-auto;
}

.secondary-button {
  @apply border-gray-300 text-gray-700 bg-white;
}

.secondary-button:disabled {
  @apply bg-gray-200 cursor-not-allowed;
}

.primary-button {
  @apply text-white bg-indigo-600 hover:bg-indigo-700;
}

.primary-button:disabled {
  @apply bg-indigo-400 cursor-not-allowed;
}

.confirm-button {
  @apply text-white bg-green-600 hover:bg-green-700;
}

.confirm-button:disabled {
  @apply bg-green-400 cursor-not-allowed;
}

.cancel-button {
  @apply text-white bg-red-600 hover:bg-red-700;
}

.cancel-button:disabled {
  @apply bg-red-400 cursor-not-allowed;
}
</style>
