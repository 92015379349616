import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import type { AxiosError, AxiosResponse } from "axios";
import {
  AdminConfigurationsApi,
  type AdminDomain,
  AdminDomainsApi,
  type AdminListPlatformList,
  type AdminPlatform,
  type AdminPlatformConfiguration,
  AdminPlatformsApi,
  type AdminRedirect,
  AdminRedirectsApi,
  type ChangeDomainConfigurationRequest,
  type CopyConfigurationRequest,
  type CreateConfigurationRequest,
  type CreatePlatformRequest,
  type CreateRedirectRequest,
  type PutDomainRequest,
  type UpdateConfigurationRequest,
  type UpdatePlatformRequest,
  type UpdateRedirectRequest,
} from "mws-platform-ts-sdk";
import { computed, type MaybeRef, type Ref } from "vue";

import type { UseQueryOptionsOmitQueryKey } from "@/common/types";

const platformApiUrl = import.meta.env.VITE_PLATFORM_API;
const adminPlatformsApi = new AdminPlatformsApi(undefined, platformApiUrl);
const adminRedirectsApi = new AdminRedirectsApi(undefined, platformApiUrl);
const adminDomainApi = new AdminDomainsApi(undefined, platformApiUrl);
const adminConfigurationsApi = new AdminConfigurationsApi(
  undefined,
  platformApiUrl,
);

export const useGetPlatformQuery = (
  platformId: Ref<string>,
  options?: UseQueryOptionsOmitQueryKey<AdminPlatform>,
) => {
  const queryKey = ["platforms", platformId];

  const queryFn = async () => {
    const response = await adminPlatformsApi.adminPlatformsPlatformIdGet({
      platformId: platformId.value,
    });

    return response.data;
  };

  return useQuery({ queryKey, queryFn, ...options });
};

export const useGetPlatformsQuery = (
  searchTerm?: Ref<string>,
  pageSize?: Ref<number>,
  offset?: Ref<number>,
  filters?: Ref<{
    platformIds?: string[];
  }>,
  options?: UseQueryOptionsOmitQueryKey<AdminListPlatformList>,
) => {
  const queryParams = computed(() => ({
    platformIds: filters?.value?.platformIds?.join(","),
    searchTerm: searchTerm?.value ?? "",
    offset: offset?.value ?? 0,
    size: pageSize?.value ?? 100,
  }));

  const queryKey = ["platforms", queryParams];

  const queryFn = async () => {
    const response = await adminPlatformsApi.adminPlatformsGet(
      queryParams.value,
    );

    return response.data;
  };

  const opts = { staleTime: Infinity, ...options };

  return useQuery({ queryKey, queryFn, ...opts });
};

export const useCreatePlatformMutation = () => {
  return useMutation({
    mutationFn: async (request: CreatePlatformRequest) => {
      const response = await adminPlatformsApi.adminPlatformsPost({
        adminPlatformsPostRequest: request,
      });

      return response.data;
    },
  });
};

export const useUpdatePlatformMutation = (
  onError?: (error: AxiosError) => void,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (request: {
      platformId: string;
      data: UpdatePlatformRequest;
    }) => {
      const response = await adminPlatformsApi.adminPlatformsPlatformIdPatch({
        platformId: request.platformId,
        adminPlatformsPlatformIdPatchRequest: request.data,
      });

      return response.data;
    },
    onSuccess: (_, request) => {
      queryClient.invalidateQueries({
        queryKey: ["platforms", request.platformId],
      });
    },
    onError,
  });
};

export const useDeletePlatformMutation = (
  onSuccess: (response: AxiosResponse<void>) => void,
  onError: (error: AxiosError) => void,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (request: { platformId: string }) =>
      adminPlatformsApi.adminPlatformsPlatformIdDelete({
        platformId: request.platformId,
      }),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["platforms"] });
      onSuccess(data);
    },
    onError,
  });
};

export const useCreateDomainMutation = (
  onSuccess: (response: AdminDomain) => void,
  onError: (error: AxiosError) => void,
) => {
  const mutationFn = async (request: {
    platformId: string;
    domain: string;
  }) => {
    const response = await adminDomainApi.adminDomainsPost({
      platformId: request.platformId,
      domain: request.domain,
    });

    return response.data;
  };

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["platforms", data.platformId],
      });
      onSuccess(data);
    },
    onError,
  });
};

export const useDeleteDomainMutation = (
  domainPlatformId: MaybeRef<string>,
  onSuccess: () => void,
  onError: (error: AxiosError) => void,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (request: { domain: string }) => {
      return await adminDomainApi.adminDomainsDelete({
        domain: request.domain,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["platforms", domainPlatformId],
      });
      onSuccess();
    },
    onError,
  });
};

export const useUpdateDomainConfigurationMutation = (
  domainPlatformId: MaybeRef<string>,
  onSuccess: (response: AdminDomain) => void,
  onError: (error: AxiosError) => void,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (request: {
      configurationId: string;
      changeDomainConfigurationRequest: ChangeDomainConfigurationRequest;
    }) => {
      const response =
        await adminDomainApi.adminDomainsConfigurationConfigurationIdPatch({
          configurationId: request.configurationId,
          adminDomainsConfigurationConfigurationIdPatchRequest:
            request.changeDomainConfigurationRequest,
        });

      return response.data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["platformConfigurations"] });
      queryClient.invalidateQueries({
        queryKey: ["platforms", domainPlatformId],
      });
      onSuccess(data);
    },
    onError,
  });
};

export const useCreatePlatformConfigurationMutation = (
  onSuccess?: (response: AdminPlatformConfiguration) => void,
  onError?: (error: AxiosError) => void,
) => {
  return useMutation({
    mutationFn: async (request: CreateConfigurationRequest) => {
      const response = await adminConfigurationsApi.adminConfigurationsPost({
        adminConfigurationsPostRequest: request,
      });

      return response.data;
    },
    onSuccess,
    onError,
  });
};

export const useCreatePlatformConfigurationFromExistingMutation = (
  onSuccess?: (response: AdminPlatformConfiguration) => void,
  onError?: (error: AxiosError) => void,
) => {
  return useMutation({
    mutationFn: async (request: {
      sourceId: string;
      copyConfigurationRequest: CopyConfigurationRequest;
    }) => {
      const response =
        await adminConfigurationsApi.adminConfigurationsSourceIdCopyPost({
          sourceId: request.sourceId,
          adminConfigurationsSourceIdCopyPostRequest:
            request.copyConfigurationRequest,
        });

      return response.data;
    },
    onSuccess,
    onError,
  });
};

export const useUpdatePlatformConfigurationMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (request: {
      configurationId: string;
      updateConfigurationRequest: UpdateConfigurationRequest;
    }) => {
      const response =
        await adminConfigurationsApi.adminConfigurationsConfigurationIdPatch({
          configurationId: request.configurationId,
          adminConfigurationsConfigurationIdPatchRequest:
            request.updateConfigurationRequest,
        });

      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["platformConfigurations"] });
    },
  });
};

export const useGetPlatformConfigurationQuery = (
  configurationId: Ref<string>,
  options?: UseQueryOptionsOmitQueryKey<AdminPlatformConfiguration>,
) =>
  useQuery({
    queryKey: ["platformConfigurations", configurationId],
    queryFn: async () => {
      const response =
        await adminConfigurationsApi.adminConfigurationsConfigurationIdGet({
          configurationId: configurationId.value,
        });

      return response.data;
    },
    ...options,
  });

export const useGetPlatformConfigurationListQuery = (
  filters?: Ref<{
    platformId?: string;
  }>,
  searchTerm?: Ref<string>,
  pageSize?: Ref<number>,
  offset?: Ref<number>,
) => {
  const queryKey = [
    "platformConfigurations",
    filters,
    {
      searchTerm,
      pageSize,
      offset,
    },
  ];

  const queryFn = async () => {
    const response = await adminConfigurationsApi.adminConfigurationsGet({
      searchTerm: searchTerm?.value,
      platformId: filters?.value.platformId,
      offset: offset?.value,
      pageSize: pageSize?.value,
    });

    return response.data;
  };

  return useQuery({
    queryKey,
    queryFn,
  });
};

export const useCreateRedirectMutation = (
  onSuccess: (response: AdminRedirect) => void,
  onError: (error: AxiosError) => void,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (request: {
      platformId: string;
      createRequest: CreateRedirectRequest;
    }) => {
      const response =
        await adminRedirectsApi.adminPlatformsPlatformIdRedirectsPost({
          platformId: request.platformId,
          adminPlatformsPlatformIdRedirectsPostRequest: request.createRequest,
        });

      return response.data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["platforms", data.platformId],
      });
      onSuccess(data);
    },
    onError,
  });
};

export const useUpdateRedirectMutation = (
  onSuccess: (response: AdminRedirect) => void,
  onError: (error: AxiosError) => void,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (request: {
      platformId: string;
      redirectId: string;
      updateRedirectRequest: UpdateRedirectRequest;
    }) => {
      const response =
        await adminRedirectsApi.adminPlatformsPlatformIdRedirectsRedirectIdPatch(
          {
            platformId: request.platformId,
            redirectId: request.redirectId,
            adminPlatformsPlatformIdRedirectsRedirectIdPatchRequest:
              request.updateRedirectRequest,
          },
        );

      return response.data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["platforms", data.platformId],
      });
      onSuccess(data);
    },
    onError,
  });
};

export const useDeleteRedirectMutation = (
  onSuccess: (response: AxiosResponse) => void,
  onError: (error: AxiosError) => void,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (request: { platformId: string; redirectId: string }) => {
      return await adminRedirectsApi.adminPlatformsPlatformIdRedirectsRedirectIdDelete(
        { platformId: request.platformId, redirectId: request.redirectId },
      );
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["platforms"] });
      onSuccess(data);
    },
    onError,
  });
};

export const usePutDomainMutation = (
  onSuccess?: (response: AdminDomain) => void,
  onError?: (error: AxiosError) => void,
) => {
  return useMutation({
    mutationFn: async (request: PutDomainRequest) => {
      const response = await adminDomainApi.adminDomainsPut({
        adminDomainsPutRequest: request,
      });

      return response.data;
    },
    onSuccess: (data) => {
      onSuccess?.(data);
    },
    onError: (error: AxiosError) => {
      onError?.(error);
    },
  });
};
