<script setup lang="ts">
import type { FormKitFrameworkContext } from "@formkit/core";
import type { AxiosError } from "axios";
import mime from "mime-lite";
import { computed, ref, useTemplateRef, watch } from "vue";
import { useToast } from "vue-toastification";

import { useUploadFileToS3Mutation } from "@/common/api/queries";
import { downloadExternalFile } from "@/common/utils/downloadExternalFile";

import BaseIcon from "./icons/BaseIcon.vue";

const props = defineProps<{
  context: FormKitFrameworkContext;
}>();

const isMultiple = computed(() => !!props.context.multiple);
const fileInput = useTemplateRef("fileInput");

type UnifiedFile = {
  file: File | null;
  url: string | null;
  name: string;
  preview: string;
};

const allFiles = ref<UnifiedFile[]>([]);
const toast = useToast();

watch(
  () => props.context.node.value,
  (newValue) => {
    if (Array.isArray(newValue)) {
      allFiles.value = newValue.map((url) => ({
        file: null,
        url,
        name:
          url && url.length > 0 ? url.split("/").pop() || "Unnamed file" : "",
        preview: url,
      }));
    } else if (typeof newValue === "string" && newValue.length > 0) {
      allFiles.value = [
        {
          file: null,
          url: newValue,
          name: newValue.split("/").pop() || "Unnamed file",
          preview: newValue,
        },
      ];
    } else {
      allFiles.value = [];
    }
  },
  { immediate: true },
);

watch(
  () => props.context.clear,
  (newValue) => {
    if (newValue) {
      allFiles.value = [];
      updateInputValue();
    }
  },
);

const filePreviews = computed(() => allFiles.value.map((item) => item.preview));

const { mutateAsync: uploadFile, isPending } = useUploadFileToS3Mutation({
  onError: (error: AxiosError) =>
    props.context.node.setErrors(
      `Something went wrong uploading your file: "${error.message}"`,
    ),
});

function onOpenDialog() {
  fileInput.value?.click();
}

function onDrop(event: DragEvent) {
  if (event.dataTransfer?.files && fileInput.value) {
    let droppedFiles = Array.from(event.dataTransfer.files);

    if (!isMultiple.value && droppedFiles.length > 1) {
      droppedFiles = [droppedFiles[0] as File];
    }

    const dT = new DataTransfer();
    droppedFiles.forEach((file) => dT.items.add(file));

    fileInput.value.files = dT.files;
    fileInput.value.dispatchEvent(new Event("change"));
  }
}

async function onChangeFile(event: Event) {
  const target = event.target as HTMLInputElement;
  const selectedFiles = target.files ? Array.from(target.files) : [];

  if (!isMultiple.value) {
    allFiles.value = [];
  }

  const newFiles: UnifiedFile[] = selectedFiles.map((file) => {
    const preview = URL.createObjectURL(file);

    return {
      file,
      url: null,
      name: file.name,
      preview,
    };
  });

  allFiles.value = [...allFiles.value, ...newFiles];

  await uploadNewFiles(newFiles);

  updateInputValue();
}

async function uploadNewFiles(files: UnifiedFile[]) {
  toast.info("Uploading...");
  for (const unifiedFile of files) {
    if (unifiedFile.file) {
      try {
        const response = await uploadFile(unifiedFile.file);
        unifiedFile.url = response.key;
      } catch (error) {
        toast.error(`Error uploading file: ${error}`);
      }
    }
  }
  toast.clear();
}

function updateInputValue() {
  const urls = allFiles.value
    .map((file) => file.url)
    .filter((url): url is string => !!url);

  const inputValue = isMultiple.value ? urls : urls[0] || null;

  props.context.node.input(inputValue);
}

function onRemoveFile(index: number) {
  allFiles.value.splice(index, 1);

  updateInputValue();
}

function downloadFile(uri: string, filename: string) {
  downloadExternalFile({ uri, filename });
}

function getMimeType(file: UnifiedFile): string {
  if (file.file) {
    return file.file.type;
  } else if (file.url) {
    return mime.getType(file.url) || "";
  } else {
    return "";
  }
}

function isImage(file: UnifiedFile): boolean {
  return getMimeType(file).startsWith("image/");
}

function isPDF(file: UnifiedFile): boolean {
  return getMimeType(file) === "application/pdf";
}

function isVideo(file: UnifiedFile): boolean {
  return getMimeType(file).startsWith("video/");
}

function onImagePreviewClick() {
  const previewUrls = filePreviews.value;
  props.context.node.emit("imagePreviewClicked", {
    imagePreviewUrl: previewUrls,
  });
}
</script>

<template>
  <input
    id="file"
    ref="fileInput"
    type="file"
    :accept="context.accept as string"
    :multiple="isMultiple"
    hidden
    v-bind="context.attrs"
    @change="onChangeFile"
  />

  <div
    v-bind="context.attrs"
    class="flex flex-col align-center my-1 rounded-md bg-gray-50"
    :class="{
      'hover:bg-gray-100 border border-dashed border-indigo-600':
        allFiles.length === 0,
      'p-4': !context.previewFull,
      'p-2': context.previewFull,
    }"
  >
    <div
      v-if="isPending"
      class="flex h-24 w-full animate-ping items-center justify-center text-center text-xl"
    >
      ...
    </div>
    <div
      v-else-if="allFiles.length > 0 && context.showPreview"
      class="flex flex-col gap-y-2"
      :class="{ 'w-full': context.previewFull }"
    >
      <div
        v-for="(item, index) in allFiles"
        :key="index"
        class="flex items-center"
        :class="{
          'border-b pb-2': index !== allFiles.length - 1 || isMultiple,
        }"
      >
        <div v-if="filePreviews[index] && isImage(item)">
          <img
            :src="item.preview"
            :class="`object-scale-down mr-4 ${
              context.previewFull ? 'h-80' : 'h-24'
            }`"
            @click="onImagePreviewClick"
          />
        </div>
        <div v-else-if="filePreviews[index] && isPDF(item)">
          <embed
            :src="item.preview"
            :class="`mr-4 ${context.previewFull ? 'h-80 w-full' : 'h-24 w-28'}`"
            type="application/pdf"
          />
        </div>
        <div v-else-if="filePreviews[index] && isVideo(item)">
          <video
            :class="`object-scale-down mr-4 ${
              context.previewFull ? 'h-80 w-full' : 'h-24 w-28'
            }`"
            autoplay
            muted
          >
            <source :src="item.preview" />
          </video>
        </div>

        <!-- Conditionally display the file name if it's not an empty string -->
        <div
          v-if="
            item.name &&
            item.name.length > 0 &&
            !context.hideName &&
            !context.previewFull
          "
          class="grow ml-2"
        >
          {{ item.name }}
        </div>

        <!-- Conditionally display the X Icon only if there is a valid file or URL -->
        <div
          v-if="item.url || item.file"
          class="flex flex-col gap-3 pl-2"
        >
          <BaseIcon
            name="heroicons:x-mark"
            class="size-8 p-1 text-indigo-600 hover:text-indigo-700 hover:bg-indigo-100 rounded cursor-pointer"
            @click="() => onRemoveFile(index)"
          />
          <BaseIcon
            v-if="item.url"
            name="heroicons:arrow-down-tray"
            class="size-8 p-1 text-indigo-600 hover:text-indigo-700 hover:bg-indigo-100 rounded cursor-pointer"
            @click="() => downloadFile(item.url!, item.name)"
          />
        </div>
      </div>
    </div>
    <div
      v-if="isMultiple || allFiles.length === 0"
      class="h-24 flex justify-center items-center cursor-pointer"
      @click="onOpenDialog"
      @drop.prevent="onDrop"
      @dragenter.prevent
      @dragover.prevent
    >
      <div class="text-indigo-600 text-sm">
        {{
          isPending
            ? ""
            : `Drop ${isMultiple ? "files" : "a file"} or click here to upload`
        }}
      </div>
    </div>
  </div>
</template>
